.treeview a svg {
  font-size: 15px;
  margin-right: 0.5em;
}

.treeview a svg.arrow {
  right: 0;
  float: right;
  margin-right: 0.5em;
}

.treeview-menu li a svg {
  font-size: 10px;
  margin-right: 0.5em;
}

.sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
.sidebar-mini.sidebar-collapse .treeview > a > svg.arrow {
  display: none;
}
