/*------------------------------------------------------------------
[All Skin Stylesheet]

Project:	Fab Admin - Responsive Admin Template
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]

1. Skin: Blue
2. Skin: Blue light
3. Skin: Black
4. Skin: Black light
5. Skin: Green
6. Skin: Green light
7. Skin: Red
8. Skin: Red light
9. Skin: Purple
10. Skin: Purple light
11. Skin: Yellow
12. Skin: Yellow light
-------------------------------------------------------------------*/

/*---Skin: Blue---*/

.skin-blue .main-header .navbar {
  background-color: #1e88e5;
}
.skin-blue .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-blue .main-header .navbar .nav > li > a:hover,
.skin-blue .main-header .navbar .nav > li > a:active,
.skin-blue .main-header .navbar .nav > li > a:focus,
.skin-blue .main-header .navbar .nav .open > a,
.skin-blue .main-header .navbar .nav .open > a:hover,
.skin-blue .main-header .navbar .nav .open > a:focus,
.skin-blue .main-header .navbar .nav > .active > a,
.skin-blue.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-blue .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-blue .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-blue .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-blue .main-header .navbar .dropdown-menu li a:hover {
    background: #1e88e5;
  }
}
.skin-blue .main-header .logo {
  background-color: #1e88e5;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-blue .main-header .logo:hover {
  background-color: #1e88e5;
}
.skin-blue .main-header li.user-header {
  background-color: #fff;
}
.skin-blue .content-header {
  background: #fff;
}
.skin-blue .content {
  background: #f4f6f9;
}
.skin-blue .wrapper,
.skin-blue .main-sidebar,
.skin-blue .left-side {
  background-color: #28373e;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-blue .user-panel > .info,
.skin-blue .user-panel > .info > a {
  color: #fff;
}
.skin-blue .sidebar {
	border-color: #1e88e5;
}
.skin-blue .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-blue .sidebar-menu > li > a {
  border-left:none;
}
.skin-blue .sidebar-menu>li:hover>a{
  color: #1e88e5;
  background-color: transparent;
}
.skin-blue.sidebar-collapse .sidebar-menu>li:hover>a{
  background-color: #28373e;
}
.skin-blue .sidebar-menu>li.active>a, .skin-blue .sidebar-menu>li.menu-open>a{
  color: #ffffff;
  background: #1e88e5;
}
.skin-blue .sidebar-menu > li.active > a,
.skin-blue .sidebar-menu > li.menu-open > a {
  color: #ffffff;
}
.skin-blue .sidebar-menu > li.active > a {
  border-left-color: #1e88e5;
}
.skin-blue .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #28373e;
}
.skin-blue .sidebar a {
  color: #afb7c3;
}
.skin-blue .sidebar a:hover {
  text-decoration: none;
}
.skin-blue .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-blue .sidebar-menu .treeview-menu > li.active > a,
.skin-blue .sidebar-menu .treeview-menu > li > a:hover {
  color: #fff;
}
.skin-blue .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-blue .sidebar-form input[type="text"],
.skin-blue .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-blue .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-blue .sidebar-form input[type="text"]:focus,
.skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-blue .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-blue.layout-top-nav .main-header > .logo {
  background-color: #1e88e5;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-blue.layout-top-nav .main-header > .logo:hover {
  background-color: #1e88e5;
}
.skin-blue .btn-blue{
	background-color: #1e88e5;
	color: #fff;
	border: 1px solid #1e88e5;
}
.skin-blue .btn-blue:hover{
	background-color: #fff;
	color: #1e88e5;	
}
.horizontal-menu .skin-blue.layout-top-nav .sidebar-menu > li.active > a {
    background: #1e88e5;
	color: #fff;
}
.skin-blue .main-header .messages-menu .dropdown-toggle i::after, .skin-blue .main-header .notifications-menu .dropdown-toggle i::after, .skin-blue .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #1e88e5;
}

/*---Skin: Blue light---*/
/*---light sidebar---*/

.skin-blue-light .main-header .navbar {
  background-color: #1e88e5;
}
.skin-blue-light .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-blue-light .main-header .navbar .nav > li > a:hover,
.skin-blue-light .main-header .navbar .nav > li > a:active,
.skin-blue-light .main-header .navbar .nav > li > a:focus,
.skin-blue-light .main-header .navbar .nav .open > a,
.skin-blue-light .main-header .navbar .nav .open > a:hover,
.skin-blue-light .main-header .navbar .nav .open > a:focus,
.skin-blue-light .main-header .navbar .nav > .active > a,
.skin-blue-light.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-blue-light .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-blue-light .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-blue-light .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-blue-light .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-blue-light .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-blue-light .main-header .navbar .dropdown-menu li a:hover {
    background: #1e88e5;
  }
}
.skin-blue-light .main-header .logo {
  background-color: #1e88e5;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-blue-light .main-header .logo:hover {
  background-color: #1e88e5;
}
.skin-blue-light .main-header li.user-header {
  background-color: #fff;
}
.skin-blue-light .content-header {
  background: #fff;
}
.skin-blue-light .content {
  background: #f4f6f9;
}
.skin-blue-light .wrapper,
.skin-blue-light .main-sidebar,
.skin-blue-light .left-side {
  background-color: #ffffff;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-blue-light .user-panel > .info,
.skin-blue-light .user-panel > .info > a {
  color: #fff;
}
.skin-blue-light .sidebar {
	border-color: #1e88e5;
}
.skin-blue-light .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-blue-light .sidebar-menu > li > a {
  border-left:none;
}
.skin-blue-light .sidebar-menu>li:hover>a, .skin-blue-light .sidebar-menu>li.active>a{
  color: #fff;
  background: #1e88e5;
}
.skin-blue-light .sidebar-menu > li.active > a,
.skin-blue-light .sidebar-menu > li.menu-open > a {
  color: #fff;
  background: #1e88e5;
}
.skin-blue-light .sidebar-menu > li.active > a {
  border-left-color: #1e88e5;
}
.skin-blue-light .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #fff;
}
.skin-blue-light .sidebar a {
  color: #afb7c3;
}
.skin-blue-light .sidebar a:hover {
  text-decoration: none;
}
.skin-blue-light .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-blue-light .sidebar-menu .treeview-menu > li.active > a,
.skin-blue-light .sidebar-menu .treeview-menu > li > a:hover {
  color: #1e88e5;
}
.skin-blue-light .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-blue-light .sidebar-form input[type="text"],
.skin-blue-light .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-blue-light .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-blue-light .sidebar-form input[type="text"]:focus,
.skin-blue-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-blue-light .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-blue-light.layout-top-nav .main-header > .logo {
  background-color: #1e88e5;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-blue-light.layout-top-nav .main-header > .logo:hover {
  background-color: #1e88e5;
}
.skin-blue-light .btn-blue{
	background-color: #1e88e5;
	color: #fff;
	border: 1px solid #1e88e5;
}
.skin-blue-light .btn-blue:hover{
	background-color: #fff;
	color: #1e88e5;	
}
.horizontal-menu .skin-blue-light.layout-top-nav .sidebar-menu > li.active > a {
    background: #1e88e5;
	color: #fff;
}
.skin-blue-light .main-header .messages-menu .dropdown-toggle i::after, .skin-blue-light .main-header .notifications-menu .dropdown-toggle i::after, .skin-blue-light .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #1e88e5;
}


/*---Skin: Black---*/

.skin-black .main-header .navbar {
  background-color: #ffffff;
}
.skin-black .main-header .navbar .nav > li > a {
  color: #67757c;
}
.skin-black .main-header .navbar .nav > li > a:hover,
.skin-black .main-header .navbar .nav > li > a:active,
.skin-black .main-header .navbar .nav > li > a:focus,
.skin-black .main-header .navbar .nav .open > a,
.skin-black .main-header .navbar .nav .open > a:hover,
.skin-black .main-header .navbar .nav .open > a:focus,
.skin-black .main-header .navbar .nav > .active > a,
.skin-black.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #666666;
}
.skin-black .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-black .main-header .navbar .sidebar-toggle:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.1);
}
.skin-black .main-header .navbar .sidebar-toggle {
  color: #67757c;
}
.skin-black .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-black .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-black .main-header .navbar .dropdown-menu li a:hover {
    background: #67757c;
  }
}
.skin-black .main-header .logo {
  background-color: #ffffff;
  color: #67757c;
  border-bottom: 0 solid transparent;
}
.skin-black .main-header .logo:hover {
  background-color: #f4f6f9;
}
.skin-black .main-header li.user-header {
  background-color: #fff;
}
.skin-black .content-header {
  background: #fff;
}
.skin-black .content {
  background: #f4f6f9;
}
.skin-black .wrapper,
.skin-black .main-sidebar,
.skin-black .left-side {
  background-color: #28373e;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-black .user-panel > .info,
.skin-black .user-panel > .info > a {
  color: #fff;
}
.skin-black .sidebar {
	border-color: #ffffff;
}
.skin-black .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-black .sidebar-menu > li > a {
  border-left:none;
}
.skin-black .sidebar-menu>li:hover>a{
  color: #ffffff;
  background-color: transparent;
}
.skin-black.sidebar-collapse .sidebar-menu>li:hover>a{
  background-color: #28373e;
}
.skin-black .sidebar-menu>li.active>a, .skin-black .sidebar-menu>li.menu-open>a{
  color: #ffffff;
  background: #28373e;
}
.skin-black .sidebar-menu > li.active > a,
.skin-black .sidebar-menu > li.menu-open > a {
  color: #ffffff;
}
.skin-black .sidebar-menu > li.active > a {
  border-left-color: #232323;
}
.skin-black .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #28373e;
}
.skin-black .sidebar a {
  color: #afb7c3;
}
.skin-black .sidebar a:hover {
  text-decoration: none;
}
.skin-black .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-black .sidebar-menu .treeview-menu > li.active > a,
.skin-black .sidebar-menu .treeview-menu > li > a:hover {
  color: #fff;
}
.skin-black .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-black .sidebar-form input[type="text"],
.skin-black .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-black .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-black .sidebar-form input[type="text"]:focus,
.skin-black .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-black .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-black.layout-top-nav .main-header > .logo {
  background-color: #67757c;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-black.layout-top-nav .main-header > .logo:hover {
  background-color: #232323;
}
.skin-black .btn-blue{
	background-color: #232323;
	color: #fff;
	border: 1px solid #232323;
}
.skin-black .btn-blue:hover{
	background-color: #fff;
	color: #232323;	
}
.horizontal-menu .skin-black.layout-top-nav .sidebar-menu > li.active > a {
    background: #232323;
	color: #fff;
}
.skin-black .main-header .messages-menu .dropdown-toggle i::after, .skin-black .main-header .notifications-menu .dropdown-toggle i::after, .skin-black .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #ffffff;
}

/*---Skin: Black light---*/
/*---light sidebar---*/

.skin-black-light .main-header .navbar {
  background-color: #28373e;
}
.skin-black-light .main-header .navbar .nav > li > a {
  color: #67757c;
}
.skin-black-light .main-header .navbar .nav > li > a:hover,
.skin-black-light .main-header .navbar .nav > li > a:active,
.skin-black-light .main-header .navbar .nav > li > a:focus,
.skin-black-light .main-header .navbar .nav .open > a,
.skin-black-light .main-header .navbar .nav .open > a:hover,
.skin-black-light .main-header .navbar .nav .open > a:focus,
.skin-black-light .main-header .navbar .nav > .active > a,
.skin-black-light.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #666666;
}
.skin-black-light .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-black-light .main-header .navbar .sidebar-toggle:hover {
  color: #666666;
  background: rgba(0, 0, 0, 0.1);
}
.skin-black-light .main-header .navbar .sidebar-toggle {
  color: #67757c;
}
.skin-black-light .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-black-light .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-black-light .main-header .navbar .dropdown-menu li a:hover {
    background: #67757c;
  }
}
.skin-black-light .main-header .logo {
  background-color: #1f2d33;
  color: #67757c;
  border-bottom: 0 solid transparent;
}
.skin-black-light .main-header .logo:hover {
  background-color: #1f2d33;
}
.skin-black-light .main-header li.user-header {
  background-color: #fff;
}
.skin-black-light .content-header {
  background: #fff;
}
.skin-black-light .content {
  background: #f4f6f9;
}
.skin-black-light .wrapper,
.skin-black-light .main-sidebar,
.skin-black-light .left-side {
  background-color: #ffffff;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-black-light .user-panel > .info,
.skin-black-light .user-panel > .info > a {
  color: #fff;
}
.skin-black-light .sidebar {
	border-color: #ffffff;
}
.skin-black-light .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-black-light .sidebar-menu > li > a {
  border-left:none;
}
.skin-black-light .sidebar-menu>li:hover>a, .skin-black-light .sidebar-menu>li.active>a{
  color: #fff;
  background: #28373e;
}
.skin-black-light .sidebar-menu > li.active > a,
.skin-black-light .sidebar-menu > li.menu-open > a {
  color: #fff;
  background: #28373e;
}
.skin-black-light .sidebar-menu > li.active > a {
  border-left-color: #232323;
}
.skin-black-light .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #fff;
}
.skin-black-light .sidebar a {
  color: #afb7c3;
}
.skin-black-light .sidebar a:hover {
  text-decoration: none;
}
.skin-black-light .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-black-light .sidebar-menu .treeview-menu > li.active > a,
.skin-black-light .sidebar-menu .treeview-menu > li > a:hover {
  color: #232323;
}
.skin-black-light .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-black-light .sidebar-form input[type="text"],
.skin-black-light .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-black-light .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-black-light .sidebar-form input[type="text"]:focus,
.skin-black-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-black-light .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-black-light.layout-top-nav .main-header > .logo {
  background-color: #67757c;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-black-light.layout-top-nav .main-header > .logo:hover {
  background-color: #232323;
}
.skin-black-light .btn-blue{
	background-color: #232323;
	color: #fff;
	border: 1px solid #232323;
}
.skin-black-light .btn-blue:hover{
	background-color: #fff;
	color: #232323;	
}
.horizontal-menu .skin-black-light.layout-top-nav .sidebar-menu > li.active > a {
    background: #232323;
	color: #fff;
}
.skin-black-light .main-header .messages-menu .dropdown-toggle i::after, .skin-black-light .main-header .notifications-menu .dropdown-toggle i::after, .skin-black-light .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #ffffff;
}

/*---Skin: Green---*/

.skin-green .main-header .navbar {
  background-color: #00acc1;
}
.skin-green .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-green .main-header .navbar .nav > li > a:hover,
.skin-green .main-header .navbar .nav > li > a:active,
.skin-green .main-header .navbar .nav > li > a:focus,
.skin-green .main-header .navbar .nav .open > a,
.skin-green .main-header .navbar .nav .open > a:hover,
.skin-green .main-header .navbar .nav .open > a:focus,
.skin-green .main-header .navbar .nav > .active > a,
.skin-green.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-green .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-green .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-green .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-green .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-green .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-green .main-header .navbar .dropdown-menu li a:hover {
    background: #0fc491;
  }
}
.skin-green .main-header .logo {
  background-color: #00acc1;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-green .main-header .logo:hover {
  background-color: #00acc1;
}
.skin-green .main-header li.user-header {
  background-color: #fff;
}
.skin-green .content-header {
  background: #fff;
}
.skin-green .content {
  background: #f4f6f9;
}
.skin-green .wrapper,
.skin-green .main-sidebar,
.skin-green .left-side {
  background-color: #28373e;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-green .user-panel > .info,
.skin-green .user-panel > .info > a {
  color: #fff;
}
.skin-green .sidebar {
	border-color: #00acc1;
}
.skin-green .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-green .sidebar-menu > li > a {
  border-left:none;
}
.skin-green .sidebar-menu>li:hover>a{
  color: #00acc1;
  background-color: transparent;
}
.skin-green.sidebar-collapse .sidebar-menu>li:hover>a{
  background-color: #28373e;
}
.skin-green .sidebar-menu>li.active>a, .skin-green .sidebar-menu>li.menu-open>a{
  color: #ffffff;
  background: #00acc1;
}
.skin-green .sidebar-menu > li.active > a,
.skin-green .sidebar-menu > li.menu-open > a {
  color: #ffffff;
}
.skin-green .sidebar-menu > li.active > a {
  border-left-color: #00acc1;
}
.skin-green .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #28373e;
}
.skin-green .sidebar a {
  color: #afb7c3;
}
.skin-green .sidebar a:hover {
  text-decoration: none;
}
.skin-green .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-green .sidebar-menu .treeview-menu > li.active > a,
.skin-green .sidebar-menu .treeview-menu > li > a:hover {
  color: #fff;
}
.skin-green .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-green .sidebar-form input[type="text"],
.skin-green .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-green .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-green .sidebar-form input[type="text"]:focus,
.skin-green .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-green .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-green.layout-top-nav .main-header > .logo {
  background-color: #0fc491;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-green.layout-top-nav .main-header > .logo:hover {
  background-color: #00acc1;
}
.skin-green .btn-blue{
	background-color: #00acc1;
	color: #fff;
	border: 1px solid #00acc1;
}
.skin-green .btn-blue:hover{
	background-color: #fff;
	color: #00acc1;	
}
.horizontal-menu .skin-green.layout-top-nav .sidebar-menu > li.active > a {
    background: #00acc1;
	color: #fff;
}
.skin-green .main-header .messages-menu .dropdown-toggle i::after, .skin-green .main-header .notifications-menu .dropdown-toggle i::after, .skin-green .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #00acc1;
}


/*---Skin: Green light---*/
/*---light sidebar---*/
.skin-green-light .main-header .navbar {
  background-color: #00acc1;
}
.skin-green-light .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-green-light .main-header .navbar .nav > li > a:hover,
.skin-green-light .main-header .navbar .nav > li > a:active,
.skin-green-light .main-header .navbar .nav > li > a:focus,
.skin-green-light .main-header .navbar .nav .open > a,
.skin-green-light .main-header .navbar .nav .open > a:hover,
.skin-green-light .main-header .navbar .nav .open > a:focus,
.skin-green-light .main-header .navbar .nav > .active > a,
.skin-green-light.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-green-light .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-green-light .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-green-light .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-green-light .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-green-light .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-green-light .main-header .navbar .dropdown-menu li a:hover {
    background: #0fc491;
  }
}
.skin-green-light .main-header .logo {
  background-color: #00acc1;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-green-light .main-header .logo:hover {
  background-color: #00acc1;
}
.skin-green-light .main-header li.user-header {
  background-color: #fff;
}
.skin-green-light .content-header {
  background: #fff;
}
.skin-green-light .content {
  background: #f4f6f9;
}
.skin-green-light .wrapper,
.skin-green-light .main-sidebar,
.skin-green-light .left-side {
  background-color: #ffffff;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-green-light .user-panel > .info,
.skin-green-light .user-panel > .info > a {
  color: #fff;
}
.skin-green-light .sidebar {
	border-color: #00acc1;
}
.skin-green-light .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-green-light .sidebar-menu > li > a {
  border-left:none;
}
.skin-green-light .sidebar-menu>li:hover>a, .skin-green-light .sidebar-menu>li.active>a{
  color: #fff;
  background: #00acc1;
}
.skin-green-light .sidebar-menu > li.active > a,
.skin-green-light .sidebar-menu > li.menu-open > a {
  color: #fff;
  background: #00acc1;
}
.skin-green-light .sidebar-menu > li.active > a {
  border-left-color: #00acc1;
}
.skin-green-light .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #ffffff;
}
.skin-green-light .sidebar a {
  color: #afb7c3;
}
.skin-green-light .sidebar a:hover {
  text-decoration: none;
}
.skin-green-light .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-green-light .sidebar-menu .treeview-menu > li.active > a,
.skin-green-light .sidebar-menu .treeview-menu > li > a:hover {
  color: #00acc1;
}
.skin-green-light .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-green-light .sidebar-form input[type="text"],
.skin-green-light .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-green-light .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-green-light .sidebar-form input[type="text"]:focus,
.skin-green-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-green-light .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-green-light.layout-top-nav .main-header > .logo {
  background-color: #0fc491;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-green-light.layout-top-nav .main-header > .logo:hover {
  background-color: #00acc1;
}
.skin-green-light .btn-blue{
	background-color: #00acc1;
	color: #fff;
	border: 1px solid #00acc1;
}
.skin-green-light .btn-blue:hover{
	background-color: #fff;
	color: #00acc1;	
}
.horizontal-menu .skin-green-light.layout-top-nav .sidebar-menu > li.active > a {
    background: #00acc1;
	color: #fff;
}
.skin-green-light .main-header .messages-menu .dropdown-toggle i::after, .skin-green-light .main-header .notifications-menu .dropdown-toggle i::after, .skin-green-light .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #00acc1;
}

/*---Skin: Red---*/

.skin-red .main-header .navbar {
  background-color: #ff002e;
}
.skin-red .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-red .main-header .navbar .nav > li > a:hover,
.skin-red .main-header .navbar .nav > li > a:active,
.skin-red .main-header .navbar .nav > li > a:focus,
.skin-red .main-header .navbar .nav .open > a,
.skin-red .main-header .navbar .nav .open > a:hover,
.skin-red .main-header .navbar .nav .open > a:focus,
.skin-red .main-header .navbar .nav > .active > a,
.skin-red.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-red .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-red .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-red .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-red .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-red .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-red .main-header .navbar .dropdown-menu li a:hover {
    background: #e73f3c;
  }
}
.skin-red .main-header .logo {
  background-color: #ff002e;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-red .main-header .logo:hover {
  background-color: #ff002e;
}
.skin-red .main-header li.user-header {
  background-color: #fff;
}
.skin-red .content-header {
  background: #fff;
}
.skin-red .content {
  background: #f4f6f9;
}
.skin-red .wrapper,
.skin-red .main-sidebar,
.skin-red .left-side {
  background-color: #28373e;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-red .user-panel > .info,
.skin-red .user-panel > .info > a {
  color: #fff;
}
.skin-red .sidebar {
	border-color: #ff002e;
}
.skin-red .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-red .sidebar-menu > li > a {
  border-left:none;
}
.skin-red .sidebar-menu>li:hover>a{
  color: #ff002e;
  background-color: transparent;
}
.skin-red.sidebar-collapse .sidebar-menu>li:hover>a{
  background-color: #28373e;
}
.skin-red .sidebar-menu>li.active>a, .skin-red .sidebar-menu>li.menu-open>a{
  color: #ffffff;
  background: #ff002e;
}
.skin-red .sidebar-menu > li.active > a,
.skin-red .sidebar-menu > li.menu-open > a {
  color: #ffffff;
}
.skin-red .sidebar-menu > li.active > a {
  border-left-color: #ff002e;
}
.skin-red .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #28373e;
}
.skin-red .sidebar a {
  color: #afb7c3;
}
.skin-red .sidebar a:hover {
  text-decoration: none;
}
.skin-red .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-red .sidebar-menu .treeview-menu > li.active > a,
.skin-red .sidebar-menu .treeview-menu > li > a:hover {
  color: #fff;
}
.skin-red .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-red .sidebar-form input[type="text"],
.skin-red .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-red .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-red .sidebar-form input[type="text"]:focus,
.skin-red .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-red .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-red.layout-top-nav .main-header > .logo {
  background-color: #e73f3c;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-red.layout-top-nav .main-header > .logo:hover {
  background-color: #ff002e;
}
.skin-red .btn-blue{
	background-color: #ff002e;
	color: #fff;
	border: 1px solid #ff002e;
}
.skin-red .btn-blue:hover{
	background-color: #fff;
	color: #ff002e;	
}
.horizontal-menu .skin-red.layout-top-nav .sidebar-menu > li.active > a {
    background: #ff002e;
	color: #fff;
}
.skin-red .main-header .messages-menu .dropdown-toggle i::after, .skin-red .main-header .notifications-menu .dropdown-toggle i::after, .skin-red .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #ff002e;	
    background-color: #00acc1;
}

/*---Skin: Red light---*/
/*---light sidebar---*/

.skin-red-light .main-header .navbar {
  background-color: #ff002e;
}
.skin-red-light .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-red-light .main-header .navbar .nav > li > a:hover,
.skin-red-light .main-header .navbar .nav > li > a:active,
.skin-red-light .main-header .navbar .nav > li > a:focus,
.skin-red-light .main-header .navbar .nav .open > a,
.skin-red-light .main-header .navbar .nav .open > a:hover,
.skin-red-light .main-header .navbar .nav .open > a:focus,
.skin-red-light .main-header .navbar .nav > .active > a,
.skin-red-light.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-red-light .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-red-light .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-red-light .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-red-light .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-red-light .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-red-light .main-header .navbar .dropdown-menu li a:hover {
    background: #e73f3c;
  }
}
.skin-red-light .main-header .logo {
  background-color: #ff002e;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-red-light .main-header .logo:hover {
  background-color: #ff002e;
}
.skin-red-light .main-header li.user-header {
  background-color: #fff;
}
.skin-red-light .content-header {
  background: #fff;
}
.skin-red-light .content {
  background: #f4f6f9;
}
.skin-red-light .wrapper,
.skin-red-light .main-sidebar,
.skin-red-light .left-side {
  background-color: #ffffff;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-red-light .user-panel > .info,
.skin-red-light .user-panel > .info > a {
  color: #fff;
}
.skin-red-light .sidebar {
	border-color: #ff002e;
}
.skin-red-light .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-red-light .sidebar-menu > li > a {
  border-left:none;
}
.skin-red-light .sidebar-menu>li:hover>a, .skin-red-light .sidebar-menu>li.active>a{
  color: #fff;
  background: #ff002e;
}
.skin-red-light .sidebar-menu > li.active > a,
.skin-red-light .sidebar-menu > li.menu-open > a {
  color: #fff;
  background: #ff002e;
}
.skin-red-light .sidebar-menu > li.active > a {
  border-left-color: #ff002e;
}
.skin-red-light .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #ffffff;
}
.skin-red-light .sidebar a {
  color: #afb7c3;
}
.skin-red-light .sidebar a:hover {
  text-decoration: none;
}
.skin-red-light .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-red-light .sidebar-menu .treeview-menu > li.active > a,
.skin-red-light .sidebar-menu .treeview-menu > li > a:hover {
  color: #ff002e;
}
.skin-red-light .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-red-light .sidebar-form input[type="text"],
.skin-red-light .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-red-light .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-red-light .sidebar-form input[type="text"]:focus,
.skin-red-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-red-light .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-red-light.layout-top-nav .main-header > .logo {
  background-color: #e73f3c;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-red-light.layout-top-nav .main-header > .logo:hover {
  background-color: #ff002e;
}
.skin-red-light .btn-blue{
	background-color: #ff002e;
	color: #fff;
	border: 1px solid #ff002e;
}
.skin-red-light .btn-blue:hover{
	background-color: #fff;
	color: #ff002e;	
}
.horizontal-menu .skin-red-light.layout-top-nav .sidebar-menu > li.active > a {
    background: #ff002e;
	color: #fff;
}

.skin-red-light .main-header .messages-menu .dropdown-toggle i::after, .skin-red-light .main-header .notifications-menu .dropdown-toggle i::after, .skin-red-light .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #ff002e;	
    background-color: #00acc1;
}


/*---Skin: Purple---*/

.skin-purple .main-header .navbar {
  background-color: #7460ee;
}
.skin-purple .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-purple .main-header .navbar .nav > li > a:hover,
.skin-purple .main-header .navbar .nav > li > a:active,
.skin-purple .main-header .navbar .nav > li > a:focus,
.skin-purple .main-header .navbar .nav .open > a,
.skin-purple .main-header .navbar .nav .open > a:hover,
.skin-purple .main-header .navbar .nav .open > a:focus,
.skin-purple .main-header .navbar .nav > .active > a,
.skin-purple.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-purple .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-purple .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-purple .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-purple .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-purple .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-purple .main-header .navbar .dropdown-menu li a:hover {
    background: #664ce1;
  }
}
.skin-purple .main-header .logo {
  background-color: #7460ee;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-purple .main-header .logo:hover {
  background-color: #7460ee;
}
.skin-purple .main-header li.user-header {
  background-color: #fff;
}
.skin-purple .content-header {
  background: #fff;
}
.skin-purple .content {
  background: #f4f6f9;
}
.skin-purple .wrapper,
.skin-purple .main-sidebar,
.skin-purple .left-side {
  background-color: #28373e;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-purple .user-panel > .info,
.skin-purple .user-panel > .info > a {
  color: #fff;
}
.skin-purple .sidebar {
	border-color: #7460ee;
}
.skin-purple .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-purple .sidebar-menu > li > a {
  border-left:none;
}
.skin-purple .sidebar-menu>li:hover>a{
  color: #7460ee;
  background-color: transparent;
}
.skin-purple.sidebar-collapse .sidebar-menu>li:hover>a{
  background-color: #28373e;
}
.skin-purple .sidebar-menu>li.active>a, .skin-purple .sidebar-menu>li.menu-open>a{
  color: #ffffff;
  background: #7460ee;
}
.skin-purple .sidebar-menu > li.active > a,
.skin-purple .sidebar-menu > li.menu-open > a {
  color: #ffffff;
}
.skin-purple .sidebar-menu > li.active > a {
  border-left-color: #7460ee;
}
.skin-purple .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #28373e;
}
.skin-purple .sidebar a {
  color: #afb7c3;
}
.skin-purple .sidebar a:hover {
  text-decoration: none;
}
.skin-purple .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-purple .sidebar-menu .treeview-menu > li.active > a,
.skin-purple .sidebar-menu .treeview-menu > li > a:hover {
  color: #fff;
}
.skin-purple .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-purple .sidebar-form input[type="text"],
.skin-purple .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-purple .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-purple .sidebar-form input[type="text"]:focus,
.skin-purple .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-purple .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-purple.layout-top-nav .main-header > .logo {
  background-color: #664ce1;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-purple.layout-top-nav .main-header > .logo:hover {
  background-color: #7460ee;
}
.skin-purple .btn-blue{
	background-color: #7460ee;
	color: #fff;
	border: 1px solid #7460ee;
}
.skin-purple .btn-blue:hover{
	background-color: #fff;
	color: #7460ee;	
}
.horizontal-menu .skin-purple.layout-top-nav .sidebar-menu > li.active > a {
    background: #7460ee;
	color: #fff;
}
.skin-purple .main-header .messages-menu .dropdown-toggle i::after, .skin-purple .main-header .notifications-menu .dropdown-toggle i::after, .skin-purple .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #7460ee;	
}

/*---Skin: Purple light---*/
/*---light sidebar---*/
.skin-purple-light .main-header .navbar {
  background-color: #7460ee;
}
.skin-purple-light .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-purple-light .main-header .navbar .nav > li > a:hover,
.skin-purple-light .main-header .navbar .nav > li > a:active,
.skin-purple-light .main-header .navbar .nav > li > a:focus,
.skin-purple-light .main-header .navbar .nav .open > a,
.skin-purple-light .main-header .navbar .nav .open > a:hover,
.skin-purple-light .main-header .navbar .nav .open > a:focus,
.skin-purple-light .main-header .navbar .nav > .active > a,
.skin-purple-light.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-purple-light .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-purple-light .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-purple-light .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-purple-light .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-purple-light .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-purple-light .main-header .navbar .dropdown-menu li a:hover {
    background: #664ce1;
  }
}
.skin-purple-light .main-header .logo {
  background-color: #7460ee;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-purple-light .main-header .logo:hover {
  background-color: #7460ee;
}
.skin-purple-light .main-header li.user-header {
  background-color: #fff;
}
.skin-purple-light .content-header {
  background: #fff;
}
.skin-purple-light .content {
  background: #f4f6f9;
}
.skin-purple-light .wrapper,
.skin-purple-light .main-sidebar,
.skin-purple-light .left-side {
  background-color: #ffffff;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-purple-light .user-panel > .info,
.skin-purple-light .user-panel > .info > a {
  color: #fff;
}
.skin-purple-light .sidebar {
	border-color: #7460ee;
}
.skin-purple-light .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-purple-light .sidebar-menu > li > a {
  border-left:none;
}
.skin-purple-light .sidebar-menu>li:hover>a, .skin-purple-light .sidebar-menu>li.active>a{
  color: #fff;
  background: #7460ee;
}
.skin-purple-light .sidebar-menu > li.active > a,
.skin-purple-light .sidebar-menu > li.menu-open > a {
  color: #fff;
  background: #7460ee;
}
.skin-purple-light .sidebar-menu > li.active > a {
  border-left-color: #7460ee;
}
.skin-purple-light .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #ffffff;
}
.skin-purple-light .sidebar a {
  color: #afb7c3;
}
.skin-purple-light .sidebar a:hover {
  text-decoration: none;
}
.skin-purple-light .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-purple-light .sidebar-menu .treeview-menu > li.active > a,
.skin-purple-light .sidebar-menu .treeview-menu > li > a:hover {
  color: #7460ee;
}
.skin-purple-light .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-purple-light .sidebar-form input[type="text"],
.skin-purple-light .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-purple-light .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-purple-light .sidebar-form input[type="text"]:focus,
.skin-purple-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-purple-light .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-purple-light.layout-top-nav .main-header > .logo {
  background-color: #664ce1;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-purple-light.layout-top-nav .main-header > .logo:hover {
  background-color: #7460ee;
}
.skin-purple-light .btn-blue{
	background-color: #7460ee;
	color: #fff;
	border: 1px solid #7460ee;
}
.skin-purple-light .btn-blue:hover{
	background-color: #fff;
	color: #7460ee;	
}
.horizontal-menu .skin-purple-light.layout-top-nav .sidebar-menu > li.active > a {
    background: #7460ee;
	color: #fff;
}
.skin-purple-light .main-header .messages-menu .dropdown-toggle i::after, .skin-purple-light .main-header .notifications-menu .dropdown-toggle i::after, .skin-purple-light .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #7460ee;	
}

/*---Skin: Yellow---*/

.skin-yellow .main-header .navbar {
  background-color: #e9ab2e;
}
.skin-yellow .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-yellow .main-header .navbar .nav > li > a:hover,
.skin-yellow .main-header .navbar .nav > li > a:active,
.skin-yellow .main-header .navbar .nav > li > a:focus,
.skin-yellow .main-header .navbar .nav .open > a,
.skin-yellow .main-header .navbar .nav .open > a:hover,
.skin-yellow .main-header .navbar .nav .open > a:focus,
.skin-yellow .main-header .navbar .nav > .active > a,
.skin-yellow.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-yellow .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-yellow .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-yellow .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-yellow .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-yellow .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-yellow .main-header .navbar .dropdown-menu li a:hover {
    background: #df9e1b;
  }
}
.skin-yellow .main-header .logo {
  background-color: #e9ab2e;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-yellow .main-header .logo:hover {
  background-color: #e9ab2e;
}
.skin-yellow .main-header li.user-header {
  background-color: #fff;
}
.skin-yellow .content-header {
  background: #fff;
}
.skin-yellow .content {
  background: #f4f6f9;
}
.skin-yellow .wrapper,
.skin-yellow .main-sidebar,
.skin-yellow .left-side {
  background-color: #28373e;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-yellow .user-panel > .info,
.skin-yellow .user-panel > .info > a {
  color: #fff;
}
.skin-yellow .sidebar {
	border-color: #e9ab2e;
}
.skin-yellow .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-yellow .sidebar-menu > li > a {
  border-left:none;
}
.skin-yellow .sidebar-menu>li:hover>a{
  color: #e9ab2e;
  background-color: transparent;
}
.skin-yellow.sidebar-collapse .sidebar-menu>li:hover>a{
  background-color: #28373e;
}
.skin-yellow .sidebar-menu>li.active>a, .skin-yellow .sidebar-menu>li.menu-open>a{
  color: #ffffff;
  background: #e9ab2e;
}
.skin-yellow .sidebar-menu > li.active > a,
.skin-yellow .sidebar-menu > li.menu-open > a {
  color: #ffffff;
}
.skin-yellow .sidebar-menu > li.active > a {
  border-left-color: #e9ab2e;
}
.skin-yellow .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #28373e;
}
.skin-yellow .sidebar a {
  color: #afb7c3;
}
.skin-yellow .sidebar a:hover {
  text-decoration: none;
}
.skin-yellow .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-yellow .sidebar-menu .treeview-menu > li.active > a,
.skin-yellow .sidebar-menu .treeview-menu > li > a:hover {
  color: #fff;
}
.skin-yellow .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-yellow .sidebar-form input[type="text"],
.skin-yellow .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-yellow .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-yellow .sidebar-form input[type="text"]:focus,
.skin-yellow .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-yellow .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-yellow.layout-top-nav .main-header > .logo {
  background-color: #df9e1b;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-yellow.layout-top-nav .main-header > .logo:hover {
  background-color: #e9ab2e;
}
.skin-yellow .btn-blue{
	background-color: #e9ab2e;
	color: #fff;
	border: 1px solid #e9ab2e;
}
.skin-yellow .btn-blue:hover{
	background-color: #fff;
	color: #e9ab2e;	
}
.horizontal-menu .skin-yellow.layout-top-nav .sidebar-menu > li.active > a {
    background: #e9ab2e;
	color: #fff;
}
.skin-yellow .main-header .messages-menu .dropdown-toggle i::after, .skin-yellow .main-header .notifications-menu .dropdown-toggle i::after, .skin-yellow .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #e9ab2e;	
}

/*---Skin: Yellow light---*/
/*---light sidebar---*/

.skin-yellow-light .main-header .navbar {
  background-color: #e9ab2e;
}
.skin-yellow-light .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-yellow-light .main-header .navbar .nav > li > a:hover,
.skin-yellow-light .main-header .navbar .nav > li > a:active,
.skin-yellow-light .main-header .navbar .nav > li > a:focus,
.skin-yellow-light .main-header .navbar .nav .open > a,
.skin-yellow-light .main-header .navbar .nav .open > a:hover,
.skin-yellow-light .main-header .navbar .nav .open > a:focus,
.skin-yellow-light .main-header .navbar .nav > .active > a,
.skin-yellow-light.layout-top-nav .mega-dropdown > a.nav-link.dropdown-toggle:hover{
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-yellow-light .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-yellow-light .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-yellow-light .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-yellow-light .main-header .navbar .sidebar-toggle:hover {
  background-color: transparent;
}
@media (max-width: 767px) {
  .skin-yellow-light .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-yellow-light .main-header .navbar .dropdown-menu li a:hover {
    background: #df9e1b;
  }
}
.skin-yellow-light .main-header .logo {
  background-color: #e9ab2e;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-yellow-light .main-header .logo:hover {
  background-color: #e9ab2e;
}
.skin-yellow-light .main-header li.user-header {
  background-color: #fff;
}
.skin-yellow-light .content-header {
  background: #fff;
}
.skin-yellow-light .content {
  background: #f4f6f9;
}
.skin-yellow-light .wrapper,
.skin-yellow-light .main-sidebar,
.skin-yellow-light .left-side {
  background-color: #ffffff;
  box-shadow: 1px 0px 20px rgba(0,0,0,0.08);
}
.skin-yellow-light .user-panel > .info,
.skin-yellow-light .user-panel > .info > a {
  color: #fff;
}
.skin-yellow-light .sidebar {
	border-color: #e9ab2e;
}
.skin-yellow-light .sidebar-menu > li.header {
  color: #afb7c3;
}
.skin-yellow-light .sidebar-menu > li > a {
  border-left:none;
}
.skin-yellow-light .sidebar-menu>li:hover>a, .skin-yellow-light .sidebar-menu>li.active>a{
  color: #fff;
  background: #e9ab2e;
}
.skin-yellow-light .sidebar-menu > li.active > a,
.skin-yellow-light .sidebar-menu > li.menu-open > a {
  color: #fff;
  background: #e9ab2e;
}
.skin-yellow-light .sidebar-menu > li.active > a {
  border-left-color: #e9ab2e;
}
.skin-yellow-light .sidebar-menu > li > .treeview-menu {
  margin: 0 0px;
  background: #ffffff;
}
.skin-yellow-light .sidebar a {
  color: #afb7c3;
}
.skin-yellow-light .sidebar a:hover {
  text-decoration: none;
}
.skin-yellow-light .sidebar-menu .treeview-menu > li > a {
  color: #afb7c3;
}
.skin-yellow-light .sidebar-menu .treeview-menu > li.active > a,
.skin-yellow-light .sidebar-menu .treeview-menu > li > a:hover {
  color: #e9ab2e;
}
.skin-yellow-light .sidebar-form {
  border-radius: 3px;
  border: 1px solid #898989;
}
.skin-yellow-light .sidebar-form input[type="text"],
.skin-yellow-light .sidebar-form .btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.59);
  border: 1px solid rgba(0, 0, 0, 0.59);
  height: 35px;
}
.skin-yellow-light .sidebar-form input[type="text"] {
  color: #fff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-yellow-light .sidebar-form input[type="text"]:focus,
.skin-yellow-light .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  color: #fff;
}
.skin-yellow-light .sidebar-form .btn {
  color: #f7f7f7;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-yellow-light.layout-top-nav .main-header > .logo {
  background-color: #df9e1b;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-yellow-light.layout-top-nav .main-header > .logo:hover {
  background-color: #e9ab2e;
}
.skin-yellow-light .btn-blue{
	background-color: #e9ab2e;
	color: #fff;
	border: 1px solid #e9ab2e;
}
.skin-yellow-light .btn-blue:hover{
	background-color: #fff;
	color: #e9ab2e;	
}
.horizontal-menu .skin-yellow-light.layout-top-nav .sidebar-menu > li.active > a {
    background: #e9ab2e;
	color: #fff;
}
.skin-yellow-light .main-header .messages-menu .dropdown-toggle i::after, .skin-yellow-light .main-header .notifications-menu .dropdown-toggle i::after, .skin-yellow-light .main-header .tasks-menu .dropdown-toggle i::after {
    border-color: #e9ab2e;	
}