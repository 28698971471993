.light-logo {
  -webkit-filter: drop-shadow(2px 1px 0 #fff) drop-shadow(-1px -1px 0 #fff);
  filter: drop-shadow(2px 1px 0 #fff) drop-shadow(-1px -1px 0 #fff);
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  height: auto;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
  padding-left: 10px;
}
